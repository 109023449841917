<!-- eslint-disable vue/max-len -->
<!-- eslint-disable max-len -->
<template>
  <div class="about-infra-component">
    <div className="max-w-[34rem] px-2 m-auto">
      <div className="font-bold text-left text-2xl">
        Sobre Psiquiatria:
      </div>
      <div className="text-justify">
        <div className="my-2">
          <span className="text-[0.1px] mr-8">.</span>A Psiquiatria é um ramo da medicina que se dedica ao estudo, diagnóstico, tratamento e prevenção dos transtornos
          mentais. Seu foco está na compreensão dos processos mentais, emocionais e comportamentais, buscando ajudar
          aqueles que enfrentam dificuldades em sua saúde mental.
        </div>
        <div className="my-2">
          <span className="text-[0.1px] mr-8">.</span>O principal objetivo da Psiquiatria é oferecer cuidados abrangentes para indivíduos que sofrem de uma ampla gama
          de condições psiquiátricas.
        </div>
        <div className="my-2">
          <span className="text-[0.1px] mr-8">.</span>A prática psiquiátrica abrange desde condições comuns, como ansiedade e depressão, até transtornos mais
          complexos, como esquizofrenia, transtorno bipolar e transtornos de personalidade. Para diagnosticar essas
          condições, os psiquiatras realizam avaliações minuciosas, considerando sintomas, histórico médico,
          comportamentos e exames clínicos.
        </div>
        <div className="my-2">
          <span className="text-[0.1px] mr-8">.</span>A abordagem da Psiquiatria é multidisciplinar, integrando aspectos biológicos, psicológicos e sociais no
          entendimento e tratamento dos transtornos mentais.
        </div>
        <div className="my-2">
          <span className="text-[0.1px] mr-8">.</span>Além de tratar os pacientes, os psiquiatras desempenham um papel vital na educação, na pesquisa e na
          conscientização sobre saúde mental. Eles trabalham para eliminar o estigma em torno das doenças mentais,
          promovendo a compreensão e a capacitação, além de contribuir para avanços no campo da Psiquiatria.
        </div>
        <div className="my-2">
          <span className="text-[0.1px] mr-8">.</span>Em resumo, a Psiquiatria é um campo essencial da medicina que se concentra no cuidado da saúde mental. Seu
          objetivo é oferecer suporte, tratamento e compreensão para indivíduos que lutam contra transtornos mentais,
          restaurar e manter o equilíbrio emocional e psicológico, permitindo uma vida mais saudável e satisfatória.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutInfraComponent',
};
</script>
